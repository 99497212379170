import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { initialize, reset } from 'redux-form';

import { addAlert } from 'components/Alerts/actions';
import { hideBusy, showBusy } from 'components/Busy/actions';
import ConfirmDialog from 'components/ConfirmDialog';
import Content from 'components/Content';
import { logout, showDialog as showFPPModal } from 'components/FPModal/actions';
import { go as navigate } from 'components/Navigate/actions';
import BasePage from 'components/Page';
import { fetch as getConfig } from 'entities/Config/actions';
import {
  add as addCustomer,
  linkToOAuth2,
  remove as removeCustomer,
  unlinkFromOAuth2,
  update as updateCustomer,
} from 'entities/Customer/actions';
import { getIdByNetParkCode } from 'entities/Facility/util';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { storeOnContext } from 'util/Context/actions';
import { clone } from 'util/index';
import CustomerForm from '../../components/CustomerForm';

/* Customer profile. */
class CustomerProfile extends BasePage {
  constructor(props) {
    super(props);

    // for managing privacy explanation
    this.state = { showPrivacyDetails: false };

    // keep track of which OAuth2 types are supported
    this.state = {
      ...this.state,
      googleOAuth2Enabled: false,
      facebookOAuth2Enabled: false,
    };

    // let's see if this is an OAuth2 callback
    if (props.location && props.customer) {
      const params = new URLSearchParams(props.location.hash);
      const state = params.get('#state');
      if ('fbLink' === state) {
        this.props.doOAuth2Link('facebook', params.get('access_token'), this.props.customer);

        // clear the params so the user can refresh
        props.history.replace(props.location.pathname);
      } else if ('fbUnlink' === state) {
        this.props.doOAuth2Unlink('facebook', params.get('access_token'), this.props.customer);

        // clear the params so the user can refresh
        props.history.replace(props.location.pathname);
      }
    }
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // is Google OAuth2 enabled?
    this.props.googleOAuth2Enabled().then((enabled) => {
      this.setState({ googleOAuth2Enabled: enabled });
    });

    // is Facebook OAuth2 enabled?
    this.props.facebookOAuth2Enabled().then((enabled) => {
      this.setState({ facebookOAuth2Enabled: enabled });
    });
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div>
        {this.props.facilities && (
          <div>
            {/* FP login */}
            {!this.props.customer && (
              <div className="alert alert-primary alert-dismissible fade show png-reservation-book-login">
                Already have an account?{' '}
                <span
                  className="png-inline-link"
                  onClick={() => {
                    this.props.fppModal();
                  }}
                >
                  Login!
                </span>
                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            )}

            {!this.props.customer && <Content name="fppAdd" showError={false} />}
            {this.props.customer && <Content name="fppEdit" showError={false} />}

            {/* profile form */}
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <CustomerForm
                    entity={this.props.customer}
                    facilities={this.props.facilities}
                    onSubmit={(values) =>
                      this.props.save(
                        getIdByNetParkCode(this.props.facilities, values.locationCode),
                        values,
                        this.props.customer,
                      )
                    }
                    onRemove={(customer, challengeResponse) => {
                      confirmAlert({
                        customUI: ({ onClose }) => {
                          return (
                            <ConfirmDialog
                              prompt="Are you sure?"
                              question={
                                'Do you want to remove your Frequent Parker profile? If you continue, your profile will immediately ' +
                                'be deactivated and will be fully removed within 7 business days. This action cannot be undone.'
                              }
                              noTitle="No"
                              noLabel="Never mind"
                              yesTitle="Yes"
                              yesLabel="Yes, Please"
                              onClose={onClose}
                              onConfirm={() =>
                                this.props.remove(
                                  getIdByNetParkCode(this.props.facilities, customer.locationCode),
                                  customer,
                                  challengeResponse,
                                )
                              }
                            />
                          );
                        },
                      });
                    }}
                  />

                  {/* linked accounts */}
                  {this.props.customer &&
                    (this.state.googleOAuth2Enabled || this.state.facebookOAuth2Enabled) && (
                      <>
                        <div className="form-row mt-5">
                          <div className="col">
                            <h2>Linked Accounts</h2>
                          </div>
                        </div>

                        {/* explanation */}
                        <div className="form-row">
                          <div className="col">
                            <p>
                              To speed up the login process, you can link your Google account, your
                              Facebook account, or both. If you do this, rest easy, we never see
                              your credentials.
                            </p>
                            {!this.state.showPrivacyDetails && (
                              <p>
                                Curious?{' '}
                                <span
                                  className="png-inline-link"
                                  onClick={() => {
                                    this.setState({ showPrivacyDetails: true });
                                  }}
                                >
                                  Learn more
                                </span>
                                .
                              </p>
                            )}
                            {this.state.showPrivacyDetails && (
                              <Content name="oAuth2Privacy" showLoader={true} showError={true} />
                            )}
                          </div>
                        </div>

                        {/* Google */}
                        {this.state.googleOAuth2Enabled && (
                          <div>
                            <div className="form-row">
                              <div className="col">
                                <h3>Google</h3>
                              </div>
                            </div>

                            {/* Google: link */}
                            {(!this.props.customer.linked ||
                              !this.props.customer.linked.google) && (
                              <div>
                                <div className="form-row">
                                  <div className="col">
                                    {this.props.thirdPartyCookiesBlocked && (
                                      <p>
                                        To link your Google account, you'll need to enable
                                        third-party cookies for this site. Sorry, it's a Google
                                        requirement, and there's nothing we can do about it.
                                      </p>
                                    )}
                                    {!this.props.thirdPartyCookiesBlocked && (
                                      <p>
                                        Link your Google account to speed up the login process. It
                                        gives you one less set of credentials to remember! And
                                        remember, we never see your Google credentials.
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className="form-row mb-3">
                                  <div className="form-group col text-center">
                                    <GoogleLogin
                                      className="btn btn-primary btn-md mt-0"
                                      clientId={process.env.REACT_APP_GOOGLE_OAUTH2_KEY}
                                      onSuccess={(googleUser) => {
                                        // log the response
                                        console.debug('Response received from Google:', googleUser);

                                        // we have a token, now pass that to the server to link the customer
                                        this.props.doOAuth2Link(
                                          'google',
                                          googleUser.getAuthResponse().id_token,
                                          this.props.customer,
                                        );
                                      }}
                                      onFailure={(e) => {
                                        if (!e.error || e.error !== 'popup_closed_by_user') {
                                          if (
                                            e.error &&
                                            e.error === 'idpiframe_initialization_failed'
                                          ) {
                                            this.props.oAuth2CookieError(e);
                                          } else {
                                            console.error('Error linking to Google', e);
                                            this.props.oAuth2LinkError(e);
                                          }
                                        }
                                      }}
                                      disabled={this.props.thirdPartyCookiesBlocked}
                                      render={(renderProps) => (
                                        <button
                                          onClick={renderProps.onClick}
                                          className="btn btn-primary btn-md mt-0"
                                        >
                                          <FontAwesomeIcon icon={['fab', 'google']} />
                                          <span> Link to Google</span>
                                        </button>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* Google: unlink */}
                            {this.props.customer.linked && this.props.customer.linked.google && (
                              <div>
                                <div className="form-row">
                                  <div className="col">
                                    {this.props.thirdPartyCookiesBlocked && (
                                      <p>
                                        To unlink your Google account, you'll need to enable
                                        third-party cookies for this site. Sorry, it's a Google
                                        requirement, and there's nothing we can do about it.
                                      </p>
                                    )}
                                    {!this.props.thirdPartyCookiesBlocked && (
                                      <div>
                                        <p>
                                          We make parking easy, and you made login easy by linking
                                          your Google account!
                                        </p>
                                        <p>
                                          Changed your mind? No worries, you can unlink it. Note
                                          that you may be asked to reauthenticate with Google to
                                          prove that it's you!
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="form-row mb-3">
                                  <div className="form-group col text-center">
                                    <GoogleLogin
                                      className="btn btn-primary btn-md mt-0"
                                      clientId={process.env.REACT_APP_GOOGLE_OAUTH2_KEY}
                                      onSuccess={(googleUser) => {
                                        // log the response
                                        console.debug('Response received from Google:', googleUser);

                                        // we have a token, now pass that to the server to unlink the customer
                                        this.props.doOAuth2Unlink(
                                          'google',
                                          googleUser.getAuthResponse().id_token,
                                          this.props.customer,
                                        );
                                      }}
                                      onFailure={(e) => {
                                        if (!e.error || e.error !== 'popup_closed_by_user') {
                                          if (
                                            e.error &&
                                            e.error === 'idpiframe_initialization_failed'
                                          ) {
                                            this.props.oAuth2CookieError(e);
                                          } else {
                                            console.error('Error unlinking from Google', e);
                                            this.props.oAuth2UnlinkError(e);
                                          }
                                        }
                                      }}
                                      disabled={this.props.thirdPartyCookiesBlocked}
                                      render={(renderProps) => (
                                        <button
                                          onClick={renderProps.onClick}
                                          className="btn btn-primary btn-md mt-0"
                                        >
                                          <FontAwesomeIcon icon={['fab', 'google']} />
                                          <span> Unlink from Google</span>
                                        </button>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        {/* Facebook */}
                        {this.state.facebookOAuth2Enabled && (
                          <div>
                            <div className="form-row">
                              <div className="col">
                                <h3>Facebook</h3>
                              </div>
                            </div>

                            {/* Facebook: link */}
                            {(!this.props.customer.linked ||
                              !this.props.customer.linked.facebook) && (
                              <div>
                                <div className="form-row">
                                  <div className="col">
                                    <p>
                                      Link your Facebook account to speed up the login process. It
                                      gives you one less set of credentials to remember! And
                                      remember, we never see your Facebook credentials.
                                    </p>
                                  </div>
                                </div>
                                <div className="form-row mb-3">
                                  <div className="form-group col text-center">
                                    <FacebookLogin
                                      version="3.1"
                                      autoLoad={false}
                                      appId={process.env.REACT_APP_FB_APP_ID}
                                      redirectUri={`${window.location.origin}/${window.location.pathname}`}
                                      responseType="token"
                                      state="fbLink"
                                      scope="public_profile"
                                      fields="name"
                                      callback={(response) => {
                                        // log the response
                                        console.debug('Response received from FB:', response);

                                        // process the response
                                        if (response && response.accessToken) {
                                          // user is logged into FB; link the accounts
                                          this.props.doOAuth2Link(
                                            'facebook',
                                            response.accessToken,
                                            this.props.customer,
                                          );
                                        } else if (
                                          response &&
                                          (typeof response.status === 'undefined' ||
                                            response.status === 'unknown')
                                        ) {
                                          // the user closed the window
                                        } else {
                                          console.error('Error linking to Facebook', response);
                                          this.props.oAuth2LinkError();
                                        }
                                      }}
                                      render={(renderProps) => (
                                        <button
                                          onClick={renderProps.onClick}
                                          className="btn btn-primary btn-md mt-0"
                                        >
                                          <FontAwesomeIcon icon={['fab', 'facebook']} />
                                          <span> Link to Facebook</span>
                                        </button>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* Facebook: unlink */}
                            {this.props.customer.linked && this.props.customer.linked.facebook && (
                              <div>
                                <div className="form-row">
                                  <div className="col">
                                    <p>
                                      We make parking easy, and you made login easy by linking your
                                      Facebook account!
                                    </p>
                                    <p>
                                      Changed your mind? No worries, you can unlink it. Note that
                                      you may be asked to reauthenticate with Facebook to prove that
                                      it's you!
                                    </p>
                                  </div>
                                </div>
                                <div className="form-row mb-3">
                                  <div className="form-group col text-center">
                                    <FacebookLogin
                                      version="3.1"
                                      autoLoad={false}
                                      appId={process.env.REACT_APP_FB_APP_ID}
                                      redirectUri={`${window.location.origin}/${window.location.pathname}`}
                                      responseType="token"
                                      state="fbUnlink"
                                      scope="public_profile"
                                      fields="name"
                                      callback={(response) => {
                                        // log the response
                                        console.debug('Response received from FB:', response);

                                        // process the response
                                        if (response && response.accessToken) {
                                          // user is logged into FB; unlink the accounts
                                          this.props.doOAuth2Unlink(
                                            'facebook',
                                            response.accessToken,
                                            this.props.customer,
                                          );
                                        } else if (
                                          response &&
                                          typeof response.status === 'undefined'
                                        ) {
                                          // the user closed the window
                                        } else {
                                          console.error('Error unlinking from Facebook', response);
                                          this.props.oAuth2UnlinkError();
                                        }
                                      }}
                                      render={(renderProps) => (
                                        <button
                                          onClick={renderProps.onClick}
                                          className="btn btn-primary btn-md mt-0"
                                        >
                                          <FontAwesomeIcon icon={['fab', 'facebook']} />
                                          <span> Unlink from Facebook</span>
                                        </button>
                                      )}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        )}
        {!this.props.facilities && (
          <div className="container-fluid">
            <div className="row">
              <h2 className="col">Unavailable</h2>
            </div>
            <div className="row">
              <div className="col">
                {!this.props.customer && (
                  <span>
                    The Frequent Parker Program application is temporarily unavailable. Please check
                    back later.
                  </span>
                )}
                {this.props.customer && (
                  <span>
                    Your Frequent Parker Program profile can't be edited right now. Please check
                    back later.
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state) => ({
  // the existing customer, if there is one
  customer: state.context.customer,

  // facilities
  facilities: state.context.facilities,

  // are third-party cookies blocked?
  thirdPartyCookiesBlocked: state.context.thirdPartyCookiesBlocked,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // save
  save: (facilityId, toSave, original) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // if update, cannot change a few things
    if (original) {
      toSave.id = original.id;
      toSave.locationCode = original.locationCode;
      toSave.alternateId = original.alternateId;
    }

    // do we have a challenge response?
    const challengeResponse = toSave.challengeResponse;
    delete toSave.challengeResponse;

    // clear the OAuth2 block
    delete toSave.oauth2;

    // add or update
    const saveCustomer = original
      ? () => dispatch(updateCustomer(facilityId, original.email, toSave, challengeResponse))
      : () => dispatch(addCustomer(facilityId, toSave, challengeResponse));

    // do the save
    return saveCustomer()
      .then((customer) => {
        // show a success message
        dispatch(
          addAlert(
            'success',
            !original
              ? `Your frequent parker profile has been created! Your frequent parker number is ${customer.alternateId.toUpperCase()}. Now go earn some points!`
              : `Your frequent parker profile has been updated.`,
            !original ? 8000 : 3000,
          ),
        );

        // update it in the context
        dispatch(storeOnContext('customer', customer));

        // return it
        return customer;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // remove
  remove: (facilityId, customer, challengeResponse) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // do the delete
    return dispatch(removeCustomer(facilityId, customer.email, challengeResponse))
      .then(() => {
        // show a success message
        dispatch(
          addAlert(
            'success',
            `We have received your request to remove your frequent parker profile.`,
            5000,
          ),
        );

        // log the user out
        return dispatch(logout()).finally(() => {
          // reset a few forms; this will force the respective pages to re-render
          dispatch(reset('fpForm'));
          dispatch(reset('customerForm'));
          dispatch(reset('reservationForm'));
          dispatch(reset('corporateInquiryForm'));

          // also clear initial values on the reservation form...
          dispatch(
            initialize(
              'reservationForm',
              {},
              {
                keepDirty: false,
                updateUnregisteredFields: true,
                keepValues: false,
              },
            ),
          );

          // ... and the corporate inquiry form
          dispatch(
            initialize(
              'corporateInquiryForm',
              {},
              {
                keepDirty: false,
                updateUnregisteredFields: true,
                keepValues: false,
              },
            ),
          );

          // go home
          dispatch(navigate('home'));
        });
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // open FPP modal
  fppModal: () => {
    dispatch(showFPPModal());
  },

  // determines if Google OAuth2 is enabled
  googleOAuth2Enabled: () => {
    return dispatch(getConfig('web.oauth2.googleEnabled'))
      .then((config) => {
        return config && config.value.toLowerCase() === 'true';
      })
      .catch((e) => {
        console.error('Error determining if we should allow Google OAuth2', e);
        return false;
      });
  },

  // determines if Facebook OAuth2 is enabled
  facebookOAuth2Enabled: () => {
    return dispatch(getConfig('web.oauth2.facebookEnabled'))
      .then((config) => {
        return config && config.value.toLowerCase() === 'true';
      })
      .catch((e) => {
        console.error('Error determining if we should allow Facebook OAuth2', e);
        return false;
      });
  },

  // link a customer with an OAuth2 provider
  doOAuth2Link: (provider, token, customer) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // create the link
    return dispatch(linkToOAuth2(provider, token, customer))
      .then((customer) => {
        // show a success message
        dispatch(
          addAlert(
            'success',
            `Your frequent parker account has been linked to your ${provider.replace(/^\w/, (c) =>
              c.toUpperCase(),
            )} account. You can now login using your ${provider.replace(/^\w/, (c) =>
              c.toUpperCase(),
            )} credentials!`,
            8000,
          ),
        );

        // update it on the context
        dispatch(storeOnContext('customer', customer));

        // return it
        return customer;
      })
      .catch((e) => {
        // is this a "profile already linked" error?
        if (e.code && (e.code === 7005 || e.code === 7006)) {
          // tell the user
          dispatch(
            addAlert(
              'error',
              `Your ${provider.replace(/^\w/, (c) =>
                c.toUpperCase(),
              )} account is already linked to a different frequent parker account! ` +
                `Each ${provider.replace(/^\w/, (c) =>
                  c.toUpperCase(),
                )} account can only be linked to a single frequent parker account.`,
              8000,
            ),
          );
        } else {
          console.error('Error linking OAuth2 account', e);
          dispatch(
            addAlert(
              'error',
              `We ran into a problem linking your accounts. Please wait a bit and then try again.`,
              6000,
            ),
          );
        }
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // error linking to OAuth2 provider
  oAuth2LinkError: () => {
    dispatch(
      addAlert(
        'error',
        `We ran into a problem unlinking your accounts. Please wait a bit and then try again.`,
        6000,
      ),
    );
  },

  // unlink a customer from an OAuth2 provider
  doOAuth2Unlink: (provider, token, customer) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // remove the link
    return dispatch(unlinkFromOAuth2(provider, token, customer))
      .then(() => {
        // show a success message
        dispatch(
          addAlert(
            'success',
            `Your frequent parker account has been unlinked from your ${provider.replace(
              /^\w/,
              (c) => c.toUpperCase(),
            )} account.`,
            4000,
          ),
        );

        // clone (to trigger a render) and clear the associated flag
        if (customer.linked) {
          customer = clone(customer);
          if (provider === 'google') {
            customer.linked.google = false;
          } else if (provider === 'facebook') {
            customer.linked.facebook = false;
          }
        }

        // update it on the context
        dispatch(storeOnContext('customer', customer));

        // return it
        return customer;
      })
      .catch((e) => {
        // is this a "customer does not exist" error?
        if (e.code && e.code === 7000) {
          // tell the user
          dispatch(
            addAlert(
              'error',
              `The ${provider.replace(/^\w/, (c) =>
                c.toUpperCase(),
              )} account you are currently signed into isn't the one linked to your frequent ` +
                "parker account. Therefore, we can't unlink it. You'll need to sign into your " +
                `linked ${provider.replace(/^\w/, (c) => c.toUpperCase())} account first.`,
              10000,
            ),
          );
        } else {
          console.error('Error unlinking OAuth2 account', e);
          dispatch(
            addAlert(
              'error',
              `We ran into a problem unlinking your accounts. Please wait a bit and then try again.`,
              6000,
            ),
          );
        }
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // error unlinking from OAuth2 provider
  oAuth2UnlinkError: () => {
    dispatch(
      addAlert(
        'error',
        `We ran into a problem unlinking your accounts. Please wait a bit and then try again.`,
        6000,
      ),
    );
  },

  // third-party cookies required
  oAuth2CookieError: () => {
    // store this on the context so that we can disable things accordingly
    dispatch(storeOnContext('thirdPartyCookiesBlocked', true));
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerProfile));

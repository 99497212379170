import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Field, reduxForm, clearSubmitErrors } from 'redux-form';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { showDialog as showFPPModal } from 'components/FPModal/actions';
import { formatZip, formatPhone } from 'util/formatters';
import { renderEnhancedField } from 'util/form/renderers';
import { clone } from 'util/index';

/* Customer form. */
class CustomerForm extends BaseForm {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        {/* notes */}
        {this.props.customer && (
          <div
            className="alert alert-primary alert-dismissible fade show png-reservation-book-login"
            role="alert"
          >
            We use your last name and email address to apply points for your reservations.
            Therefore, you cannot change them. If you would like to book this reservation under
            another name, please{' '}
            <span
              className="png-inline-link"
              onClick={() => {
                this.props.fppModal();
              }}
            >
              logout
            </span>{' '}
            first.
            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        {/* first/last name */}
        <div className="form-row">
          {/* first name */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="text"
              label="First Name"
              name="customer.firstName"
              autoComplete="given-name"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="First Name"
              tooltip={`Your first name`}
              maxLength="32"
              required={true}
              disabled={this.props.submitting}
            />
          </div>

          {/* last name */}
          <div className="form-group col has-error">
            <Field
              type="text"
              label="Last Name"
              name="customer.lastName"
              autoComplete="family-name"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Last Name"
              tooltip={
                `Your last name` +
                (this.props.customer ? '; to earn points, you cannot change this' : '')
              }
              maxLength="64"
              required={true}
              disabled={this.props.customer || this.props.submitting}
            />
          </div>
        </div>

        {/* if prepay, we need address info */}
        {this.props.prepay ? (
          <div>
            {/* address lines */}
            <div className="form-row">
              {/* address line 1 */}
              <div className="form-group col-md-6 has-error">
                <Field
                  type="text"
                  label="Address Line 1"
                  name="customer.address.street"
                  autoComplete="address-line1"
                  labelClassName="col-form-label col-form-label-lg"
                  className="form-control form-control-lg"
                  component={renderEnhancedField}
                  placeholder="Address Line 1"
                  tooltip={`Your street address`}
                  maxLength="255"
                  required={true}
                  disabled={this.props.submitting}
                />
              </div>

              {/* address line 2 */}
              <div className="form-group col-md-6 has-error">
                <Field
                  type="text"
                  label="Address Line 2"
                  name="customer.address.street2"
                  autoComplete="address-line2"
                  labelClassName="col-form-label col-form-label-lg"
                  className="form-control form-control-lg"
                  component={renderEnhancedField}
                  placeholder="Address Line 2 (Optional)"
                  tooltip={`Any supplementary address information necessary to find you, like a suite or apartment number`}
                  maxLength="64"
                  required={false}
                  disabled={this.props.submitting}
                />
              </div>
            </div>

            {/* city/state/zip */}
            <div className="form-row">
              {/* city */}
              <div className="form-group col-sm-4 has-error">
                <Field
                  type="text"
                  label="City"
                  name="customer.address.city"
                  autoComplete="address-level2"
                  labelClassName="col-form-label col-form-label-lg"
                  className="form-control form-control-lg"
                  component={renderEnhancedField}
                  placeholder="City"
                  tooltip={`Your city`}
                  maxLength="64"
                  required={true}
                  disabled={this.props.submitting}
                />
              </div>

              {/* state */}
              <div className="form-group col-sm-5 has-error">
                <Field
                  type="select"
                  label="State/Province"
                  name="customer.address.state"
                  autoComplete="address-level1"
                  labelClassName="col-form-label col-form-label-lg"
                  className="form-control form-control-lg"
                  component={renderEnhancedField}
                  placeholder="State/Province"
                  tooltip={`Your state or province`}
                  required={true}
                  disabled={this.props.submitting}
                >
                  {this.renderStateOptions()}
                </Field>
              </div>

              {/* zip */}
              <div className="form-group col-sm-3 has-error">
                <Field
                  type="text"
                  label="Postal Code"
                  name="customer.address.zip"
                  autoComplete="postal-code"
                  labelClassName="col-form-label col-form-label-lg"
                  className="form-control form-control-lg"
                  component={renderEnhancedField}
                  minLength="5"
                  maxLength="10"
                  pattern="^(\d{5})$|^(\d{5}-\d{4})$|^([A-Z]\d{1}[A-Z]-\d{1}[A-Z]\d{1})$"
                  placeholder="Postal Code"
                  tooltip={`Your postal code`}
                  format={formatZip}
                  required={true}
                  disabled={this.props.submitting}
                />
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {/* email/phone */}
        <div className="form-row">
          {/* email */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="email"
              label="Email Address"
              name="customer.email"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              autoComplete="email"
              component={renderEnhancedField}
              placeholder="Email Address"
              tooltip={
                `Your email address` +
                (this.props.original
                  ? '; you cannot change this when editing an existing reservation'
                  : this.props.customer
                  ? '; to earn points, you cannot change this'
                  : '')
              }
              maxLength="128"
              required={true}
              disabled={this.props.original || this.props.customer || this.props.submitting}
            />
          </div>

          {/* phone */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="tel"
              label="Personal Phone"
              name="customer.personalPhone"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              autoComplete="tel-national"
              component={renderEnhancedField}
              placeholder="Personal Phone"
              tooltip={`Your personal phone number, just in case we need to reach you while parked`}
              format={formatPhone}
              minLength="12"
              maxLength="12"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required={true}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            <button
              type="button"
              onClick={() => this.props.onPrevious()}
              className="btn btn-primary btn-lg png-reservation-book-previous"
            >
              Back
            </button>
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.props.submitting
              }
            >
              Next
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
CustomerForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // allow us to add new initial values
  enableReinitialize: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(CustomerForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // set initial values, but be careful not to smash any currently set values
  initialValues: ownProps.customer
    ? {
        customer: clone(ownProps.customer),
        ...state.form.reservationForm.values,
      }
    : state.form.reservationForm.values,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch) => ({
  // open FPP modal
  fppModal: () => {
    dispatch(showFPPModal());
  },
});

// turn this into a container component
CustomerForm = withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomerForm));

// set default props
CustomerForm.defaultProps = {
  form: 'reservationForm',
};

export default CustomerForm;

import $ from 'jquery';

// other actions
import {
  fetch as fetchCustomer,
  fetchByOAuth2Token as fetchCustomerViaOAuth2,
} from 'entities/Customer/actions';
import { storeOnContext, clearFromContext } from 'util/Context/actions';

// action types
export const LOGOUT = 'customer/LOGOUT';
export const SHOW_DIALOG = 'customer/SHOW_DIALOG';

// logout
export const notifyLogout = () => ({
  type: LOGOUT,
});

// show dialog
export function showDialog() {
  // using thunk middleware, so we return a function
  return (dispatch, getState) => {
    // fire the correct modal
    if (getState().context.customer) {
      $('#png-fp-card').modal();
    } else {
      $('#png-fp-login').modal();
    }
  };
}

/**
 * Logs a customer in (by fetching his profile).
 */
export function login(facilityId, email, fpNumber) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // note that the service call is starting
    console.debug('Preparing to fetch the customer...');

    // defer to the service to do the fetch
    return dispatch(fetchCustomer(facilityId, email, fpNumber))
      .then((customer) => {
        // success!
        console.debug(`Fetch successful for customer with email ${email} and FP# ${fpNumber}`);

        // return the customer
        return customer;
      })
      .then((customer) => {
        // store the customer on the context
        dispatch(storeOnContext('customer', customer));

        // propagate object
        return customer;
      });
  };
}

/**
 * Logs a customer in via OAuth2 (by fetching his profile).
 */
export function loginViaOAuth2(provider, token) {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // note that the service call is starting
    console.debug('Preparing to fetch the customer via OAuth2...');

    // defer to the service to do the fetch
    return dispatch(fetchCustomerViaOAuth2(provider, token))
      .then((customer) => {
        // success!
        console.debug(`Fetch successful for customer at OAuth2 provider ${provider}`);

        // return the customer
        return customer;
      })
      .then((customer) => {
        // store the customer on the context
        dispatch(storeOnContext('customer', customer));

        // propagate object
        return customer;
      });
  };
}

/**
 * Logs a customer out.
 */
export function logout() {
  // using thunk middleware, so we return a function
  return (dispatch) => {
    // clearing the customer from the context is good enough to log someone out
    dispatch(clearFromContext('customer'));

    // dispatch a general action to let other components know we logged out
    dispatch(notifyLogout());

    // resolve the promise
    return Promise.resolve();
  };
}

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { BasePureComponent } from 'components/Base';
import $ from 'jquery';

import './styles.scss';

/* Cancellation assistance form. */
class CancelAssistance extends BasePureComponent {
  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // function that renders the LiveAgent form
    const laFunction =
      'function la(d, src, c) {' +
      '  var t = d.scripts[d.scripts.length - 1],' +
      "  s = d.createElement('script');" +
      "  s.id = 'la_x2s6df8d';" +
      '  s.async = true;' +
      '  s.src = src;' +
      '  s.onload = s.onreadystatechange = ' +
      '    function () {' +
      '      var rs = this.readyState;' +
      "      if (rs && rs != 'complete' && rs != 'loaded') {" +
      '        return;' +
      '      }' +
      '      c();' +
      '    };' +
      '  t.parentElement.insertBefore(s, t.nextSibling);' +
      '}';

    // add the LiveAgent form
    const script = document.createElement('script');
    script.innerHTML = laFunction;
    document.body.appendChild(script);
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <>
        <div className="alert alert-primary show png-reservation-cancel-assistance">
          Need help cancelling a reservation? We'd be happy to assist. Just{' '}
          {/* <span className="png-inline-link" onClick={() => $('#png-cancel-assistance').modal()}> */}
          <span
            className="png-inline-link"
            onClick={() => {
              // launch the modal
              $('#png-cancel-assistance').modal();

              // render the form
              window.la(document, 'https://png.ladesk.com/scripts/track.js', function () {
                window.LiveAgent.createForm(
                  'djj0k97o',
                  document.getElementById('png-cancel-assistance-form'),
                );
              });
            }}
          >
            contact us
          </span>
          .
        </div>
        <div
          className="modal fade"
          id="png-cancel-assistance"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="png-cancel-assistance"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md" role="document">
            <div className="modal-content">
              <div className="modal-header png-cancel-assistance-header png-background">
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body png-cancel-assistance-form">
                {/* LiveAgent will insert the content */}
                <div id="png-cancel-assistance-form"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

// turn this into a container component
export default withRouter(connect(null, null)(CancelAssistance));

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Field, reduxForm, change, formValueSelector, clearSubmitErrors } from 'redux-form';
import moment from 'moment/moment';
import $ from 'jquery';

import { BaseForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { renderEnhancedField } from 'util/form/renderers';
import MessageBox from 'components/MessageBox/index';
import './styles.scss';

/* Check-in/out times form. */
class TimesForm extends BaseForm {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // we should have a start date
    const start = props.start;

    // minimum time is midnight on that date...
    let minStart = moment(start).startOf('day');

    // ... unless it is today; then it is the quarter
    // hour immediately preceding 75 minutes from now
    if (start.isSame(moment(), 'day')) {
      minStart = moment().add(75, 'minutes');
      if (minStart.minute() >= 1 && minStart.minute() <= 14) {
        minStart.minute(0);
      } else if (minStart.minute() >= 15 && minStart.minute() <= 29) {
        minStart.minute(15);
      } else if (minStart.minute() >= 30 && minStart.minute() <= 44) {
        minStart.minute(30);
      } else {
        minStart.minute(45);
      }
    }

    // for date management
    this.state = {
      ...this.state,
      minStart,
      minEnd: undefined,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    console.log({
      start: this.props.start,
      end: this.props.end,
      minStart: this.state.minStart,
      minEnd: this.state.minEnd,
    });

    // if the start date is before the minimum start time, update it
    if (this.props.start.isBefore(this.state.minStart)) {
      this.props.dispatch(change(this.props.form, 'start', this.state.minStart.unix()));
    }

    // if the start and end dates are the same, the minimum end time
    // is the start time plus an hour
    if (this.props.start.isSame(this.props.end, 'day')) {
      const minEnd = moment(this.props.start).add(1, 'hour');
      if (!this.state.minEnd || this.state.minEnd.unix() !== minEnd.unix()) {
        // note it on the state
        this.setState({ minEnd: minEnd });

        // also update the form
        this.props.dispatch(change(this.props.form, 'end', minEnd.unix()));
      }
    }
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // create a date message
    const dateMessage = (date, checkIn) => {
      var question = `What time will you be ${checkIn ? 'checking in' : 'checking out'}`;
      var format = 'dddd, LL';
      if ($(window).width() < 450) {
        question = `${checkIn ? 'Check in time' : 'Check out time'}`;
        format = 'ddd, M/D';
      } else if ($(window).width() < 800) {
        format = 'ddd, l';
      }
      return `${question} on ${moment(date).format(format)}?`;
    };

    // render times
    const renderTimes = (min, date) => {
      // render each quarter hour of the day
      var times = [];

      // for every hour...
      for (let i = 0; i < 24; i++) {
        // ... and every quarter hour...
        for (let j = 0; j < 4; j++) {
          // create a moment for the value; it's the given date with the time applied
          const value = moment(date)
            .hour(i)
            .minute(j * 15)
            .second(0)
            .milliseconds(0);

          // add the time
          times.push(
            <option
              key={value.unix()}
              value={
                // the value is the given date with the time applied, in Unix format
                value.unix()
              }
              disabled={
                // disable the time if it's before the minimum
                min && value.unix() < min.unix()
              }
            >
              {/* render the time in 12-hour format */}
              {`${i % 12 || 12}:${j * 15 ? j * 15 : '00'} ${i < 12 ? 'AM' : 'PM'}`}
            </option>,
          );
        }
      }
      return times;
    };

    // render
    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit}
        className="png-reservation-book-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && (
          <div className="has-error">
            <div className="png-form-error">{this.props.error}</div>
          </div>
        )}

        <div className="form-row">
          {/* check in time */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="select"
              label={dateMessage(this.props.start, true)}
              name="start"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Check In Time"
              tooltip={`The time at which you will be checking in on the day of your arrival`}
              required={true}
              disabled={this.props.submitting}
              normalize={(value) => Number(value)}
            >
              {renderTimes(this.state.minStart, this.props.start)}
            </Field>
          </div>

          {/* check out time */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="select"
              label={dateMessage(this.props.end, false)}
              name="end"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Check Out Time"
              tooltip={`The time at which you will be checking out on the day of your departure`}
              required={true}
              disabled={this.props.submitting}
              normalize={(value) => Number(value)}
            >
              {renderTimes(this.state.minEnd, this.props.end)}
            </Field>
          </div>
        </div>

        {/* note */}
        <div className="form-row">
          <div className="form-group col-md has-error">
            <MessageBox flavor="info">
              Just provide your best guess. It's fine if you're off by a little bit. We'll take care
              of it at check out!
            </MessageBox>
          </div>
        </div>

        {/* buttons */}
        <div className="form-row png-reservation-buttons">
          <div className="form-group png-reservation-button col-5">
            {/* back */}
            <button
              type="button"
              onClick={() => this.props.onPrevious()}
              className="btn btn-primary btn-lg png-reservation-book-previous"
            >
              Back
            </button>
          </div>

          {/* spacer */}
          <div className="form-group col-2"></div>

          {/* next */}
          <div className="form-group png-reservation-button col-5">
            <button
              type="submit"
              className="btn btn-primary btn-lg png-reservation-book-next"
              disabled={
                (!this.props.submitFailed && (this.props.invalid || !this.state.htmlValid)) ||
                this.props.dateTimeError ||
                this.props.submitting
              }
            >
              Next
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
TimesForm = reduxForm({
  // preserve form data throughout the flow
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,

  // clear form-level errors on change
  onChange: (_, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },

  // validate the date
  validate: (values) => {
    const errors = {};

    // make sure the start is at least an hour in the future
    if (values.start && values.start < moment().add(60, 'minutes').unix()) {
      errors.start = 'Check in must be at least an hour from now';
    }

    // make sure the end is at least an hour after start
    else if (
      values.start &&
      values.end &&
      values.end < moment.unix(values.start).add(60, 'minutes').unix()
    ) {
      errors.end = 'Check out must be at least an hour after check in';
    }

    return errors;
  },
})(TimesForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // drop-off date; will already be set
  start: moment.unix(formValueSelector(ownProps.form)(state, 'start')),

  // pick-up date; will already be set
  end: moment.unix(formValueSelector(ownProps.form)(state, 'end')),

  // date/time errors
  dateTimeError:
    state.form.reservationForm && state.form.reservationForm.syncErrors
      ? state.form.reservationForm.syncErrors.start || state.form.reservationForm.syncErrors.end
      : null,
});

// turn this into a container component
TimesForm = withRouter(connect(mapStateToProps, null)(TimesForm));

// set default props
TimesForm.defaultProps = {
  form: 'reservationForm',
};

export default TimesForm;

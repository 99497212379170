import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { BasePureComponent } from 'components/Base';
import FPP from './components/FPP';
import BookBox from './components/BookBox';
import OverviewMap from './components/OverviewMap';
import SojernBeacon from 'components/SojernBeacon';
import MessageBox from 'components/MessageBox/index';

/* Home page. */
class Home extends BasePureComponent {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div id="png-home" className="png-home">
        <div>
          {!this.props.status?.parking ? (
            <>
              <MessageBox flavor="warning">
                Our online reservation system is undergoing maintenance. Please call{' '}
                <Link to={'/locations'}>one of our lots</Link> directly to make a reservation.
              </MessageBox>
            </>
          ) : (
            <BookBox />
          )}
          <div className="mt-5" />
          <FPP />
          <OverviewMap />
        </div>
        <SojernBeacon id="5230" />
      </div>
    );
  }
}

// map state to properties relevant to this component
function mapStateToProps(state, _) {
  return {
    // status
    status: state.context.status,
  };
}

// turn this into a container component
export default withRouter(connect(mapStateToProps)(Home));

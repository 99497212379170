import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment/moment';
import { formValueSelector, change, initialize, reset, clearSubmitErrors } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BasePage from 'components/Page';
import Retry from 'components/Retry';
import Loading from 'components/Loading';
import { showDialog as showFPPModal } from 'components/FPModal/actions';
import { addAlert } from 'components/Alerts/actions';
import { go as navigate } from 'components/Navigate/actions';
import { send as sendSOS } from 'util/SOS/actions';
import { showBusy, hideBusy } from 'components/Busy/actions';
import { storeOnContext } from 'util/Context/actions';
import { fetchServices, fetchQuotes } from 'entities/Facility/actions';
import { fetch as fetchCustomer } from 'entities/Customer/actions';
import { getIdByNetParkCode, getIdByFacilityCode } from 'entities/Facility/util';
import {
  fetch as fetchReservation,
  add as addReservation,
  update as updateReservation,
} from 'entities/Reservation/actions';
import LocationForm from './components/LocationForm';
import DatesForm from './components/DatesForm';
import TimesForm from './components/TimesForm';
import ServicesForm from './components/ServicesForm';
import PackagesForm from './components/PackagesForm';
import CustomerForm from './components/CustomerForm';
import VehicleForm from './components/VehicleForm';
import PayForm from './components/PayForm';
import ConfirmForm from './components/ConfirmForm';
import * as c from './constants';
import './styles.scss';

/* Book reservation. */
class BookReservation extends BasePage {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for step management
    this.state = {
      ...this.state,
      locationCode: null,
      services: [],
      packages: [],
      prepay: false,
      step: 1,
      jumpIn: false,
      jumping: false,
    };

    // for edit management
    this.state = {
      ...this.state,
      fetchError: false,
      notFound: false,
      loading: false,
      original: null,
    };

    // start with a clean form
    this.props.resetForm();

    // if we can, default the facility
    this.props.setFacility(localStorage.getItem('selectedFacility'));
  }

  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // if the parking provider is down, we can't be here
    if (!this.props.status?.parking) {
      // go home
      return this.props.parkingProviderDisabled();
    }

    // if we are editing a reservation, load it
    if (!this.state.loading) {
      this.loadReservation(this.props);
    }

    // let's see if we have a specific facility for an addition;
    // if this is an edit, we still need to do this to make sure
    // the facility gets onto the state
    var facilityId;
    if (this.props.match && this.props.match.params && this.props.match.params.facilityId) {
      // find this facility
      if (this.props.facilities) {
        for (var i = 0; i < this.props.facilities.length; i++) {
          if (String(this.props.facilities[i].id) === String(this.props.match.params.facilityId)) {
            // set it on the form
            facilityId = this.props.facilities[i].id;
            this.props.setFacility(facilityId);

            // skip the location choice
            this.setState({
              locationCode: this.props.facilities[i].netParkCode,
              step: c.STEPS.LOCATION + 1,
            });
            break;
          }
        }
      }

      // if we found a facility, let's see if we have a specific end time
      if (facilityId) {
        if (this.props.match && this.props.match.params && this.props.match.params.end) {
          // if we have an end time, we should have a start time, but
          // let's be sure
          if (this.props.match && this.props.match.params && this.props.match.params.start) {
            // jump into the flow at the times so the user can confirm
            this.setState({
              step: c.STEPS.DATES,
              jumpIn: true,
            });
            this.props.setDates(
              Number(this.props.match.params.start),
              Number(this.props.match.params.end),
            );
          }
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // parent, for lifecycle logging
    super.componentDidUpdate(prevProps, prevState);

    // if we are editing a reservation, load it
    if (!this.state.loading) {
      this.loadReservation(this.props);
    }

    // edge case; if we don't have a location, make sure we're on step 1
    if (!this.props.facilityId) {
      // new object
      this.setState({ step: c.STEPS.LOCATION });
    }

    // if we jumped in, finish the jump...
    if (this.state.jumpIn && !this.state.jumping) {
      this.setState({ jumping: true });
      this.onNext({
        facilityId: this.props.facilityId,
        start: this.props.start,
        end: this.props.end,
      });
    }
  }

  loadReservation(props) {
    if (
      !this.state.notFound &&
      props.match &&
      props.match.params &&
      props.match.params.reservationId &&
      props.match.params.facilityId &&
      props.match.params.customerId
    ) {
      // if we already have the desired reservation, do nothing
      if (
        this.state.original &&
        String(this.state.original.id) === String(props.match.params.reservationId)
      ) {
        return;
      }

      // loading...
      this.setState({
        fetchError: false,
        notFound: false,
        loading: true,
        original: null,
      });

      // find this reservation
      props
        .fetchReservation(
          props.match.params.facilityId,
          props.match.params.customerId,
          props.match.params.reservationId,
        )
        .then((reservation) => {
          // one quirk; we always get back a location code on the
          // customer, even if he's not a registered customer at the
          // location, and this can cause problems when quoting; if
          // the logged in customer matches what we got back, we
          // leave it alone, otherwise we clear that field
          if (
            !this.props.customer ||
            !this.props.customer.locationCode ||
            this.props.customer.lastName !== reservation.customer.lastName ||
            this.props.customer.email !== reservation.customer.email ||
            this.props.customer.locationCode !== reservation.customer.locationCode
          ) {
            // either there is no logged in customer, the reservation is
            // for someone other than the logged in customer, or the logged
            // in customer's primary location doesn't match the reservation's
            // primary location; this is valid even if the reservation was
            // made by an FPP customer because you have to be logged in to
            // use points
            delete reservation.customer.locationCode;
          }

          // set it on the state
          this.setState({
            fetchError: false,
            notFound: false,
            original: reservation,
          });
        })
        .catch((e) => {
          // log it
          console.error('Error fetching reservation:', e);

          // is this a "reservation not found" error?
          if (e.code && e.code === 6000) {
            // this is a special case
            this.setState({
              notFound: true,
            });
          }

          // flag it so we can show an error
          this.setState({ fetchError: true });
        })
        .finally(() => {
          // no longer loading
          this.setState({
            loading: false,
          });
        });
    } else if (this.state.original) {
      // this happens if the user chooses to create a new reservation
      // from within the edit flow; start everything over
      this.setState({
        fetchError: false,
        notFound: false,
        loading: false,
        original: null,
      });

      props.cancel();
    }
  }

  fetchPackages = (values) => {
    // we should not have a customer at this point, but we can if the user
    // got past the customer screen and then backed up; it can cause problems
    // with quoting (if not an FPP customer at this location)
    const useCustomer =
      this.state.locationCode &&
      this.props.customer &&
      this.state.locationCode === this.props.customer.locationCode;

    // invoke package selection
    return this.props
      .fetchPackages(
        values.facilityId,
        values.start,
        values.end,
        useCustomer ? this.props.customer.email : null,
        values.services,
        values.fppRedeemed,
      )
      .then((packages) => {
        // does this browser support Apple Pay?
        let applePaySupported = false;
        try {
          applePaySupported = window.ApplePaySession && window.ApplePaySession.canMakePayments();
        } catch {
          // not supported
        }

        // if we have no payment providers available, filter out prepaid packages
        if (
          !this.props.status?.payments?.creditCard &&
          !this.props.status?.payments?.payPal &&
          (!applePaySupported || !this.props.status?.payments?.applePay) &&
          !this.props.status?.payments?.googlePay
        ) {
          packages = packages.filter((p) => !p.prepay);
        }
        return packages;
      });
  };

  onNext = (values) => {
    // if necessary, call the correct handler
    switch (this.state.step) {
      case c.STEPS.LOCATION:
        // capture the location's netPark code
        if (values.facilityId && this.props.facilities) {
          for (var i = 0; i < this.props.facilities.length; i++) {
            if (String(this.props.facilities[i].id) === String(values.facilityId)) {
              // put it on the state...
              this.setState({
                locationCode: this.props.facilities[i].netParkCode,
              });
              break;
            }
          }
        }

        // increment the step
        this.setState({ step: c.STEPS.DATES });
        break;
      case c.STEPS.DATES:
        // if we jumped in, we're now done
        if (this.state.jumpIn) {
          this.setState({ jumpIn: false, jumping: false });
        }

        // increment the step
        this.setState({ step: c.STEPS.TIMES });
        break;
      case c.STEPS.TIMES:
        // check for services...
        this.props
          .fetchServices(values.facilityId)
          .then((services) => {
            // save them on the state for later
            this.setState({ services: services });

            // if we didn't get services, we skip that screen
            if (!services || services.length === 0) {
              // move straight to package selection
              this.fetchPackages(values).then((packages) => {
                // make sure we got some
                if (packages && packages.length > 0) {
                  // save them on the state for later
                  this.setState({ packages: packages });

                  // move to package selection
                  this.setState({ step: c.STEPS.PACKAGES });
                } else {
                  // go to date selection
                  this.setState({
                    step: c.STEPS.LOCATION + 1,
                  });
                }

                // if we jumped in, we're now done
                if (this.state.jumpIn) {
                  this.setState({ jumpIn: false, jumping: false });
                }
              });
            } else {
              // move to service selection
              this.setState({
                step: c.STEPS.SERVICES,
              });

              // if we jumped in, we're now done
              if (this.state.jumpIn) {
                this.setState({ jumpIn: false, jumping: false });
              }
            }
          })
          .catch((e) => {
            // skip services
            console.error("Error fetching services; we'll skip that screen", e);
            this.setState({
              step: c.STEPS.PACKAGES,
            });
          });
        break;
      case c.STEPS.SERVICES:
        // fetch packages before moving on
        this.fetchPackages(values).then((packages) => {
          // make sure we got some
          if (packages && packages.length > 0) {
            // save them on the state for later
            this.setState({ packages: packages });

            // move to package selection
            this.setState({ step: c.STEPS.PACKAGES });
          }
        });
        break;
      case c.STEPS.PACKAGES:
        // are we prepaying, and is the amount > 0?
        var prepay = false;
        if (values.rateName && this.state.packages) {
          for (var j = 0; j < this.state.packages.length; j++) {
            if (this.state.packages[j].rateName === values.rateName) {
              prepay = this.state.packages[j].prepay && this.state.packages[j].total;
              break;
            }
          }
        }
        this.setState({ prepay: prepay });

        // increment the step
        this.setState({ step: this.state.step + 1 });
        break;
      case c.STEPS.VEHICLE:
        // we must be prepaying
        this.setState({
          step: c.STEPS.PAY,
        });
        break;
      default:
        // increment the step
        if (this.state.step < (this.state.prepay ? c.STEPS.PAY : c.STEPS.VEHICLE)) {
          this.setState({ step: this.state.step + 1 });
        }
        break;
    }

    // scroll to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  onPrevious = (values) => {
    // scroll to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    // if necessary, call the correct handler
    switch (this.state.step) {
      case c.STEPS.PACKAGES:
        // if we don't have services, we skip that screen
        this.setState({
          step:
            this.state.services && this.state.services.length > 1
              ? c.STEPS.SERVICES
              : c.STEPS.TIMES,
        });
        break;
      case c.STEPS.LOCATION + 1:
        // if this is an edit, we can't go back
        if (this.state.original) {
          break;
        } else {
          // fall through
        }

      // eslint-disable-line no-fallthrough
      default:
        // decrement the step
        if (this.state.step > c.STEPS.LOCATION) {
          this.setState({ step: this.state.step - 1 });
        }
        break;
    }

    // scroll to the top
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  onConfirm = (reservation) => {
    // add the location code to the reservation...
    reservation.locationCode = this.state.locationCode;

    // ... and the customer, if he doesn't already have one
    if (reservation.customer && !reservation.customer.locationCode) {
      reservation.customer.locationCode = this.state.locationCode;
    }

    // if the customer location code doesn't match the reservation location code, clear
    // the customer's alternate ID, which will cause the server to ignore any FP information
    if (reservation.customer && reservation.customer.locationCode !== reservation.locationCode) {
      delete reservation.customer.alternateId;
    }

    // strip spaces from CC number
    if (
      reservation.payment &&
      reservation.payment.creditCard &&
      reservation.payment.creditCard.number
    ) {
      reservation.payment.creditCard.number = reservation.payment.creditCard.number.replace(
        / /g,
        '',
      );
    }

    // save
    return this.props.save(reservation, this.state.original).then((reservation) => {
      // if the reservation involved points, we refresh the customer
      if (
        reservation &&
        this.props.customer &&
        reservation.customer.alternateId &&
        this.props.customer.alternateId === reservation.customer.alternateId &&
        reservation.fppRedeemed &&
        reservation.fppRedeemed > 0 &&
        this.props.facilities
      ) {
        this.props.refreshCustomer(this.props.customer, this.props.facilities);
      }

      // go to the details page
      if (reservation) {
        const facilityId = getIdByFacilityCode(this.props.facilities, reservation.facilityCode);
        this.props.history.push(
          `/reservations/${facilityId}/${reservation.customer.email}/${reservation.id}?new=true`,
          { newBooking: true },
        );
      }
    });
  };

  renderSummary(progress) {
    // values we'll need
    const start = this.props.start;
    const end = this.props.end;
    const facilities = this.props.facilities;
    const facilityId = this.props.facilityId;
    const prepaid = this.props.prepaid ? this.props.prepaid : 0;
    const payOnExit = this.props.payOnExit ? this.props.payOnExit : 0;

    // find the facility name
    var facilityName = null;
    if (facilities) {
      for (var i = 0; i < facilities.length; i++) {
        if (facilities[i].id === Number(facilityId)) {
          facilityName = facilities[i].name;
          break;
        }
      }
    }

    // date/time format; if times have not been selected, we don't show them
    let format = 'ddd, l';
    if (this.state.step > c.STEPS.TIMES) {
      format += ' h:mm A';
    }

    return (
      <div className="container-fluid png-reservation-book-summary">
        <div className="row">
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            Location
          </div>
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            Check In
          </div>
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            Check Out
          </div>
          <div className="col-sm-3 d-none d-sm-block text-nowrap png-reservation-book-summary-name">
            {prepaid === 0 && payOnExit === 0 && 'Total'}
            {prepaid > 0 && 'Pay Now'}
            {prepaid === 0 && payOnExit > 0 && 'Pay on Exit'}
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">Location: </span>
            {facilityName && facilityName && <span>{facilityName}</span>}
          </div>
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">Check In: </span>
            {this.state.step > c.STEPS.DATES && start && (
              <span>{moment.unix(start).format(format)}</span>
            )}
          </div>
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">Check Out: </span>
            {this.state.step > c.STEPS.DATES && end && (
              <span>{moment.unix(end).format(format)}</span>
            )}
          </div>
          <div className="col-sm-3 text-nowrap png-reservation-book-summary-value">
            <span className="d-sm-none">
              {prepaid === 0 && payOnExit === 0 && 'Total: '}
              {prepaid > 0 && 'Pay Now: '}
              {prepaid === 0 && payOnExit > 0 && 'Pay on Exit: '}
            </span>
            {this.state.step >= c.STEPS.PACKAGES && (
              <span>
                {prepaid > 0 && <span>${prepaid.toFixed(2)}</span>}
                {prepaid === 0 && payOnExit > 0 && <span>${payOnExit.toFixed(2)}</span>}
              </span>
            )}
          </div>
        </div>

        {/* progress bar */}
        <div className="progress png-reservation-book-progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: progress + '%' }}
            aria-valuenow={progress / 100}
            aria-valuemin="0"
            aria-valuemax="100"
          />
        </div>
      </div>
    );
  }

  render() {
    // parent, for lifecycle logging
    super.render();

    // error other than "not found"?
    if (this.state.fetchError && !this.state.notFound) {
      return <Retry onRefresh={() => this.loadReservation(this.props)} />;
    }

    // jumping in?
    else if (this.state.jumpIn) {
      return (
        <div className="container">
          <div className="row">
            <div className="col png-page-header">New Reservation</div>
          </div>
          <div className="row">
            <div className="col-12 text-center png-content-loading">
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    // still loading?
    else if (this.state.loading) {
      return (
        <div className="container">
          <div className="row">
            <div className="col png-page-header">Edit Reservation</div>
          </div>
          <div className="row">
            <div className="col-12 text-center png-content-loading">
              <Loading />
            </div>
          </div>
        </div>
      );
    }

    // step to header mappings
    const headers = [];
    headers[c.STEPS.LOCATION] = 'Select a location';
    headers[c.STEPS.DATES] = 'Select check in and check out dates';
    headers[c.STEPS.TIMES] = 'Select check in and check out times';
    headers[c.STEPS.SERVICES] = 'How else can we serve you?';
    headers[c.STEPS.PACKAGES] = 'Select a rate';
    headers[c.STEPS.CUSTOMER] = 'Tell us about yourself';
    headers[c.STEPS.VEHICLE] = 'Tell us about your vehicle (optional)';
    headers[c.STEPS.PAY] = 'Provide payment details';
    headers[c.STEPS.CONFIRM] = 'Please confirm your reservation';

    // current progress
    const lastStep = this.state.prepay ? c.STEPS.PAY : c.STEPS.VEHICLE;
    const progress = (() => {
      switch (this.state.step) {
        case c.STEPS.LOCATION:
          return 0;
        case lastStep:
          return 100;
        default:
          return (100 / (lastStep - 1)) * (this.state.step - 1);
      }
    })();

    // cancellation function
    const cancel = () => {
      // clean up the state
      this.setState({ original: null });

      // invoke the callback
      this.props.cancel();
    };

    // render
    return (
      <div>
        {!this.props.facilities && (
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <h2>Unavailable</h2>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <span>
                  Reservation booking is temporarily unavailable. Rest assured that we are working
                  hard to resolve this problem. Please check back later!
                </span>
              </div>
            </div>
          </div>
        )}

        {this.props.facilities && (
          <div className="container-fluid png-reservation-book">
            {/* notice */}
            {this.state.notFound && (
              <div className="row">
                <div className="col">
                  <div className="alert alert-info alert-dismissible fade show" role="alert">
                    We couldn't find the requested reservation. Why not create a new one?
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>
              </div>
            )}

            {/* page header */}
            <div className="row">
              <div className="col png-page-header">
                {this.state.original && <span>Edit</span>}
                {!this.state.original && <span>New</span>} Reservation
              </div>
            </div>

            {/* step header */}
            <div className="row">
              <div className="col">
                <h2>{headers[this.state.step]}</h2>
              </div>
            </div>

            {/* summary */}
            {this.renderSummary(progress)}

            {/* content */}
            <div className="modal-body">
              {/* errors */}
              {this.props.error && (
                <div className="has-error">
                  <div className="png-form-error">{this.props.error}</div>
                </div>
              )}

              {/* select location */}
              {this.state.step === c.STEPS.LOCATION && (
                <LocationForm
                  facilities={this.props.facilities}
                  customer={this.props.customer}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* dates */}
              {this.state.step === c.STEPS.DATES && (
                <DatesForm
                  facilities={this.props.facilities}
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* times */}
              {this.state.step === c.STEPS.TIMES && (
                <TimesForm
                  facilities={this.props.facilities}
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* services */}
              {this.state.step === c.STEPS.SERVICES && (
                <ServicesForm
                  services={this.state.services}
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* packages */}
              {this.state.step === c.STEPS.PACKAGES && (
                <PackagesForm
                  facilities={this.props.facilities}
                  original={this.state.original}
                  locationCode={this.state.locationCode}
                  customer={this.props.customer}
                  services={this.state.services}
                  packages={this.state.packages}
                  onPointChange={(values) =>
                    // re-fetch packages
                    this.fetchPackages({
                      ...values,
                      customer: this.props.customer,
                    }).then((packages) => {
                      // make sure we got some
                      if (packages && packages.length > 0) {
                        // save them on the state for later
                        this.setState({ packages: packages });
                      }

                      return packages;
                    })
                  }
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* customer */}
              {this.state.step === c.STEPS.CUSTOMER && (
                <CustomerForm
                  customer={this.props.customer}
                  original={this.state.original}
                  prepay={this.state.prepay}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onNext(values)}
                />
              )}

              {/* vehicles */}
              {this.state.step === c.STEPS.VEHICLE && (
                <VehicleForm
                  customer={this.props.customer}
                  original={this.state.original}
                  prepay={this.state.prepay}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) =>
                    this.state.prepay ? this.onNext(values) : this.onConfirm(values)
                  }
                />
              )}

              {/* pay */}
              {this.state.step === c.STEPS.PAY && (
                <PayForm
                  original={this.state.original}
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onConfirm(values)}
                />
              )}

              {/* confirm */}
              {this.state.step === c.STEPS.CONFIRM && (
                <ConfirmForm
                  onCancel={cancel}
                  onPrevious={(values) => this.onPrevious(values)}
                  onSubmit={(values) => this.onConfirm(values)}
                />
              )}

              {/* fee disclaimer */}
              {[
                c.STEPS.LOCATION,
                c.STEPS.DROP_OFF,
                c.STEPS.RETURN,
                c.STEPS.SERVICES,
                c.STEPS.PACKAGES,
                c.STEPS.CUSTOMER,
                c.STEPS.VEHICLE,
                c.STEPS.PAY,
                c.STEPS.CONFIRM,
              ].includes(this.state.step) && (
                <div className="row">
                  <div className="col text-center png-reservation-book-disclaimer">
                    <FontAwesomeIcon icon="info-circle" /> Taxes & Fees includes applicable taxes,
                    surcharges and online reservation fee.
                  </div>
                </div>
              )}

              {/* FP login */}
              {!this.props.customer && (
                <div
                  className="alert alert-primary alert-dismissible fade show png-reservation-book-login"
                  role="alert"
                >
                  Want to earn some points on this stay?{' '}
                  <span
                    className="png-inline-link"
                    onClick={() => {
                      this.props.fppModal();
                    }}
                  >
                    Login
                  </span>{' '}
                  to your Frequent Parker Program account!
                  <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // status
  status: state.context.status,

  // the logged in customer, if there is one
  customer: state.context.customer,

  // facilities
  facilities: state.context.facilities,

  // form values necessary for summary
  facilityId: formValueSelector(ownProps.formName)(state, 'facilityId'),
  start: formValueSelector(ownProps.formName)(state, 'start'),
  end: formValueSelector(ownProps.formName)(state, 'end'),
  selectedPackage: formValueSelector(ownProps.formName)(state, 'rateName'),
  prepaid: formValueSelector(ownProps.formName)(state, 'prepaid'),
  payOnExit: formValueSelector(ownProps.formName)(state, 'payOnExit'),
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // parking provider disabled
  parkingProviderDisabled: () => {
    // show an alert
    dispatch(
      addAlert(
        'warning',
        'Our online reservation system is undergoing maintenance. Please call ' +
          'one of our lots directly to make a reservation.',
        8000,
      ),
    );

    // go home
    dispatch(navigate('home'));
  },

  // resets the form
  resetForm: () => {
    dispatch(reset(ownProps.formName));
    dispatch(clearSubmitErrors(ownProps.formName));
  },

  // sets the facility on the form
  setFacility: (facilityId) => {
    if (facilityId) {
      // set the ID
      dispatch(change(ownProps.formName, 'facilityId', Number(facilityId)));
    } else {
      // clear any old ID
      dispatch(change(ownProps.formName, 'facilityId', null));
    }
  },

  // sets the dates on the form
  setDates: (start, end) => {
    dispatch(change(ownProps.formName, 'start', start));
    dispatch(change(ownProps.formName, 'end', end));
  },

  // find a reservation
  fetchReservation: (facilityId, email, reservationId) => {
    return dispatch(fetchReservation(facilityId, email, reservationId)).then((reservation) => {
      // initialize the form with the reservation
      dispatch(
        initialize(
          ownProps.formName,
          {
            facilityId: facilityId,
            ...reservation,

            // because we don't net out payments on reservation modifications, we need
            // to prime the UI to prompt for full payment; this amount will be updated
            // as appropriate if the user selects a new package
            payment: { amount: reservation.prepaid ? reservation.prepaid : undefined },
          },
          {
            keepDirty: false,
            updateUnregisteredFields: true,
            keepValues: false,
          },
        ),
      );

      return reservation;
    });
  },

  // fetches available services
  fetchServices: (facilityId) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // fetch the services
    return dispatch(fetchServices(facilityId))
      .catch((e) => {
        // send an SOS
        dispatch(sendSOS('Error fetching services', e));

        // log it
        console.error('Error fetching services; this is bad', e);
        return null;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // fetches available packages
  fetchPackages: (facilityId, start, end, customerId, services, fppRedeemed) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // fetch the packages (quotes)
    return dispatch(fetchQuotes(facilityId, start, end, customerId, services, fppRedeemed))
      .then((packages) => {
        // did we find some?
        if (!packages || packages.length === 0) {
          // toss an alert
          dispatch(addAlert('info', 'Sorry, the facility is sold out for the chosen dates.', 8000));
        }

        return packages;
      })
      .catch((e) => {
        // there are a few legit errors
        if (e.code && e.code === 22) {
          // toss an alert
          dispatch(addAlert('info', 'Sorry, the facility is sold out for the chosen dates.', 8000));
        } else if (e.code && e.code === 6018) {
          // toss an alert
          dispatch(
            addAlert(
              'info',
              'Sorry, the facility does not accept reservations of that length.',
              4000,
            ),
          );
        } else {
          // toss an alert
          dispatch(
            addAlert(
              'error',
              'We ran into a problem. Sorry about that! Please wait a minute and try again. ' +
                'If the problem persists, please contact support.',
              8000,
            ),
          );

          // send an SOS
          dispatch(sendSOS('Error fetching packages', e));

          // log it
          console.error('Error fetching packages; this is bad', e);
        }
        return null;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // cancels the workflow
  cancel: () => {
    // reset the form...
    dispatch(reset(ownProps.formName));

    // ... and any submission errors
    dispatch(clearSubmitErrors(ownProps.formName));

    // also clear the initial values
    dispatch(
      initialize(
        ownProps.formName,
        {},
        {
          keepDirty: false,
          updateUnregisteredFields: true,
          keepValues: false,
        },
      ),
    );

    // finally, navigate to the "new reservation" url
    dispatch(navigate('newReservation'));
  },

  // saves a reservation
  save: (toSave, original) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // add or update
    const saveReservation = original ? updateReservation : addReservation;

    // if update, cannot change a few things
    if (original) {
      toSave.id = original.id;
      toSave.locationCode = original.locationCode;
    }

    // if the vehicle doesn't have the minimum number of fields, get rid of it
    if (
      toSave.vehicle &&
      (!toSave.vehicle.state ||
        toSave.vehicle.state.length === 0 ||
        !toSave.vehicle.license ||
        toSave.vehicle.license.length === 0)
    ) {
      delete toSave.vehicle;
    }

    // prepaid?
    if (toSave.payment && toSave.payment.amount && toSave.payment.amount > 0) {
      // via credit card?
      if (toSave.payment.type === 'creditCard') {
        // copy customer's address to the credit card
        if (toSave.payment.creditCard && toSave.customer) {
          toSave.payment.creditCard.address = toSave.customer.address;
        }

        // we sometimes end up with extra spaces in card data (autocomplete?)
        toSave.payment.creditCard.issuer = toSave.payment.creditCard?.issuer.trim();
        toSave.payment.creditCard.number = toSave.payment.creditCard?.number.trim();
        toSave.payment.creditCard.expiration = toSave.payment.creditCard?.expiration.trim();
        toSave.payment.creditCard.cvv = toSave.payment.creditCard?.cvv.trim();

        // and somehow people are able to get 4 digit years through
        if (toSave.payment.creditCard.expiration?.length === 6) {
          toSave.payment.creditCard.expiration =
            toSave.payment.creditCard.expiration.substring(0, 2) +
            toSave.payment.creditCard.expiration.substring(4, 6);
        }
      } else {
        delete toSave.payment.creditCard;
      }
    } else {
      delete toSave.payment;
    }

    // do the save
    return dispatch(saveReservation(toSave.facilityId, toSave))
      .then((reservation) => {
        // clear the form and reset all initial values
        dispatch(reset(ownProps.formName));
        dispatch(clearSubmitErrors(ownProps.formName));
        dispatch(
          initialize(
            ownProps.formName,
            {},
            {
              keepDirty: false,
              updateUnregisteredFields: true,
              keepValues: false,
            },
          ),
        );

        // show a success message
        dispatch(
          addAlert(
            'success',
            !original
              ? `Your reservation has been created! It is number ${reservation.id}. Check your email for a message confirming your reservation. See you at the lot!`
              : `Reservation ${reservation.id} has been updated. Check your email for a message confirming the ` +
                  `details. See you at the lot!`,
            !original ? 8000 : 3000,
          ),
        );

        // return it
        return reservation;
      })
      .catch((e) => {
        // we understand a few different errors
        if (e.code) {
          let message = undefined;
          switch (e.code) {
            case 1:
              message = `Something isn't quite right in your reservation. Please double-check everything and try again.`;
              break;
            case 6012:
              message = 'You do not have enough points to complete the reservation.';
              break;
            case 6015:
              message =
                'We were not able to process payment using the provided card. Please try another.';
              break;
            case 6019:
              message =
                'We were unable to complete your reservation. This facility is sold out for the chosen dates.';
              break;
            case 8000:
              message = 'The payment card you provided is invalid. Please try another.';
              break;
            case 8001:
              message = 'The payment card you provided is invalid. Please try another.';
              break;
            case 8002:
              message = 'The payment card you provided was declined. Please try another.';
              break;
            case 8004:
              message =
                'The security code you provided with your payment card is invalid. Please check it.';
              break;

            default:
              // will be handled below
              break;
          }

          // did we recognize it?
          if (message) {
            dispatch(addAlert('error', message, 8000));
            return null;
          }
        }

        // toss a generic alert
        dispatch(
          addAlert(
            'error',
            'We ran into a problem saving your reservation. Sorry about that! Please ' +
              ' wait a minute and try again. If the problem persists, please contact support.',
            8000,
          ),
        );

        // send an SOS
        dispatch(sendSOS(`Error saving reservation: ${JSON.stringify(toSave)}`, e));

        // log it
        console.error('Error saving reservation; this is bad', e);
        return null;
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },

  // refreshes the logged in customer
  refreshCustomer: (customer, facilities) => {
    // refresh the customer
    if (customer && facilities) {
      dispatch(
        fetchCustomer(
          getIdByNetParkCode(facilities, customer.locationCode),
          customer.email,
          customer.alternateId,
        ),
      )
        .then((customer) => {
          // refresh it on the context
          dispatch(storeOnContext('customer', customer));
        })
        .catch((e) => {
          // eat it
          console.error('Error refreshing customer', e);
        });
    }
  },

  // opens FPP modal
  fppModal: () => {
    dispatch(showFPPModal());
  },

  // new reservation
  newReservation: () => {
    dispatch(navigate('newReservation'));
  },
});

// turn this into a container component
BookReservation = withRouter(connect(mapStateToProps, mapDispatchToProps)(BookReservation));

// set default props
BookReservation.defaultProps = {
  formName: 'reservationForm',
};

export default BookReservation;

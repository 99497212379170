import { LOGOUT } from 'components/FPModal/actions';

// define the reducer; we clear the form on logout
export const reservationForm = (state, action) => {
  switch (action.type) {
    case LOGOUT:
      // clear everything
      return {};
    default:
      return state;
  }
};

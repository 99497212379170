import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Field, reduxForm, clearSubmitErrors, formValueSelector, change } from 'redux-form';
import Reaptcha from 'reaptcha';

import { BaseEditForm } from 'components/Form';
import { checkValidity } from 'components/Form/utility';
import { processErrors } from 'components/Form';
import { formatZip, formatPhone } from 'util/formatters';
import { renderEnhancedField } from 'util/form/renderers';

/* Corporate inquiry form. */
class CorporateInquiryForm extends BaseEditForm {
  constructor(props) {
    super(props);

    // for the challenge
    this.challengeInstance = null;
  }

  submit = (values, dispatch, props) => {
    // make the call to inquire
    return this.props
      .onSubmit(values)
      .catch((e) => {
        // process the error(s); this will invoke the fail handler
        processErrors(
          e,
          dispatch,
          'We ran into a problem! Please wait a few minutes and then try again.',
        );
      })
      .finally(() => {
        // reset the challenge
        if (this.challengeInstance) {
          this.challengeInstance.reset();
        }
      });
  };

  render() {
    // parent, for lifecycle logging
    super.render();

    return (
      <Form
        id={this.props.form}
        onSubmit={this.props.handleSubmit(this.submit)}
        className="png-form png-corporate-form"
        onChange={() => {
          // check HTML5 validity; this is necessary for user typing, and we do
          // it on a slight delay to account for dynamic fields that may appear
          checkValidity(this);
        }}
        onBlur={() => {
          // check HTML5 validity; this is necessary for browser auto-fills
          checkValidity(this);
        }}
      >
        {/* errors */}
        {this.props.error && <div className="png-form-error">{this.props.error}</div>}

        {/* inquirer information */}
        <div className="form-row">
          <div className="col">
            <h3>Contact Information</h3>
          </div>
        </div>

        {/* first/last name */}
        <div className="form-row">
          {/* first name */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="text"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="First Name"
              tooltip={`Your first name`}
              maxLength="32"
              required={true}
              disabled={this.props.submitting}
            />
          </div>

          {/* last name */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="text"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Last Name"
              tooltip={`Your last name`}
              maxLength="64"
              required={true}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* email/phone */}
        <div className="form-row">
          {/* email */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="email"
              label="Email Address"
              name="email"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              autoComplete="email"
              component={renderEnhancedField}
              placeholder="Email Address"
              tooltip={`Your email address`}
              maxLength="128"
              required={true}
              disabled={this.props.submitting}
            />
          </div>

          {/* phone */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="tel"
              label="Phone"
              name="workPhone"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              autoComplete="tel-national"
              component={renderEnhancedField}
              placeholder="Phone"
              tooltip={`Your phone number`}
              format={formatPhone}
              minLength="12"
              maxLength="12"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              required={true}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* title */}
        <div className="form-row">
          {/* title */}
          <div className="form-group col-sm-6 has-error">
            <Field
              type="text"
              label="Title"
              name="title"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Title"
              tooltip={`Your title within the organization`}
              maxLength="32"
              required={false}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* organization information */}
        <div className="form-row">
          <div className="col">
            <h3>Organization Information</h3>
          </div>
        </div>

        {/* name/website */}
        <div className="form-row">
          <div className="form-group col-sm-6 has-error">
            <Field
              type="text"
              label="Name"
              name="organization.name"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Name"
              tooltip={`The organization's name`}
              maxLength="64"
              required={true}
              disabled={this.props.submitting}
            />
          </div>
          <div className="form-group col-sm-6 has-error">
            <Field
              type="url"
              label="Website"
              name="organization.website"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Website"
              tooltip={`The organization's website`}
              maxLength="128"
              required={false}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* address lines */}
        <div className="form-row">
          <div className="form-group col-md-6 has-error">
            <Field
              type="text"
              label="Address Line 1"
              name="organization.address.street"
              autoComplete="address-line1"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Address Line 1"
              tooltip={`The organization's street address`}
              maxLength="255"
              required={true}
              disabled={this.props.submitting}
            />
          </div>
          <div className="form-group col-md-6 has-error">
            <Field
              type="text"
              label="Address Line 2"
              name="organization.address.street2"
              autoComplete="address-line2"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Address Line 2 (Optional)"
              tooltip={`Any supplementary address information necessary to find the organization, like a suite number`}
              maxLength="64"
              required={false}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* city/state/zip */}
        <div className="form-row">
          {/* city */}
          <div className="form-group col-sm-4 has-error">
            <Field
              type="text"
              label="City"
              name="organization.address.city"
              autoComplete="address-level2"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="City"
              tooltip={`The organization's city`}
              maxLength="64"
              required={true}
              disabled={this.props.submitting}
            />
          </div>

          {/* state */}
          <div className="form-group col-sm-5 has-error">
            <Field
              type="select"
              label="State/Province"
              name="organization.address.state"
              autoComplete="address-level1"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="State/Province"
              tooltip={`The organization's state or province`}
              required={true}
              disabled={this.props.submitting}
            >
              {this.renderStateOptions()}
            </Field>
          </div>

          {/* zip */}
          <div className="form-group col-sm-3 has-error">
            <Field
              type="text"
              label="Postal Code"
              name="organization.address.zip"
              autoComplete="postal-code"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              minLength="5"
              maxLength="10"
              pattern="^(\d{5})$|^(\d{5}-\d{4})$|^([A-Z]\d{1}[A-Z]-\d{1}[A-Z]\d{1})$"
              placeholder="Postal Code"
              tooltip={`The organization's postal code`}
              format={formatZip}
              required={true}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* other information */}
        <div className="form-row">
          <div className="col">
            <h3>Other Information</h3>
          </div>
        </div>

        {/* name/website */}
        <div className="form-row">
          <div className="form-group col-sm-4 has-error">
            <Field
              type="select"
              label="Travelers"
              name="travelers"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Travelers"
              tooltip={`The expected number of travelers`}
              required={true}
              disabled={this.props.submitting}
            >
              <option key="" />
              <option key="1-4" value="1-4">
                1-4
              </option>
              <option key="5-9" value="5-9">
                5-9
              </option>
              <option key="10-14" value="10-14">
                10-14
              </option>
              <option key="15+" value="15+">
                15+
              </option>
            </Field>
          </div>
          <div className="form-group col-sm-8 has-error">
            <Field
              type="select"
              label="Primary Parking Location"
              name="primaryLocation"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Primary Parking Location"
              tooltip="Your organization's primary parking location"
              required={true}
              disabled={this.props.submitting}
              normalize={(value) => Number(value)}
            >
              {this.renderFacilityOptions(this.props.facilities, false)}
            </Field>
          </div>
        </div>

        {/* referral */}
        <div className="form-row">
          <div className="form-group col has-error">
            <Field
              type="text"
              label="Where Did You Hear About Us?"
              name="referral"
              labelClassName="col-form-label col-form-label-lg"
              className="form-control form-control-lg"
              component={renderEnhancedField}
              placeholder="Where Did You Hear About Us?"
              tooltip={`Let us know how you heard about us`}
              required={false}
              disabled={this.props.submitting}
            />
          </div>
        </div>

        {/* challenge */}
        <div className="form-row">
          <div className="form-group col text-center">
            <Reaptcha
              ref={(ref) => (this.challengeInstance = ref)}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              size="normal"
              badge="inline"
              onVerify={(response) => {
                console.debug('Got challenge response', response);
                this.props.setResponse(response);
              }}
              onExpire={() => {
                console.debug('Challenge response expired');
                this.props.setResponse(null);
              }}
            />
          </div>
        </div>

        {/* inquire button */}
        <div className="form-row">
          <div className="form-group col text-center">
            <button
              type="submit"
              disabled={
                !this.props.challengeResponse ||
                (!this.props.submitFailed &&
                  (this.props.invalid ||
                    !this.state.htmlValid ||
                    this.props.pristine ||
                    this.props.submitting))
              }
              className="btn btn-primary btn-lg"
            >
              Submit
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

// decorate with reduxForm()
CorporateInquiryForm = reduxForm({
  // force reinitialization when the initial values change
  enableReinitialize: true,

  // clear form-level errors on change
  onChange: (values, dispatch, props) => {
    if (props.error) {
      dispatch(clearSubmitErrors(props.form));
    }
  },
})(CorporateInquiryForm);

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // challenge response
  challengeResponse: formValueSelector(ownProps.form)(state, 'challengeResponse'),

  // pull initial values from the passed in customer
  initialValues: ownProps.customer
    ? {
        ...ownProps.customer,
      }
    : null,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps) => ({
  // setResponse
  setResponse: (challengeResponse) => {
    dispatch(change(ownProps.form, 'challengeResponse', challengeResponse));
  },
});

// turn this presentation component into a container component
CorporateInquiryForm = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CorporateInquiryForm),
);

// set default props
CorporateInquiryForm.defaultProps = {
  form: 'corporateInquiryForm',
};

export default CorporateInquiryForm;
